import { LocationMarkerIcon, PhoneIcon, ViewBoardsIcon } from '@heroicons/react/outline'
import React, { useRef } from 'react'

import useIsInViewport from '../../../components/useIsInViewport'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function AcessoRapido(props) {
    const acessoRapioRef = useRef(null)
    const acessoRapidoVisible = useIsInViewport(acessoRapioRef)

    return (
        <div className='mx-auto'>
            <div className='bg-gradient-to-r bg-green-600 h-80 mx-auto rounded-b-3xl shadow-lg flex justify-end'>
                <img className='right-0 top-0 object-contain w-96 h-fit lg:mr-10' src={require(`../../../assets/desenhos/medical-care.png`)} alt="medical-care" />
            </div>

            <div
                ref={acessoRapioRef}
                className={classNames(
                    acessoRapidoVisible ? 'animate-fromBottom visible' : 'invisile',
                    'grid grid-cols-1 md:grid-cols-2 bg-white -mt-44 md:-mt-32 p-4 shadow-2xl rounded-lg text-green-600 gap-4 max-w-xs md:max-w-2xl mx-auto'
                )}>
                <button onClick={() => props.planosRef.current?.scrollIntoView({ behavior: 'smooth' })} className='flex-1 p-4 flex flex-col gap-4 justify-center items-center shadow rounded-lg bg-gray-100 cursor-pointer transition duration-200 hover:shadow-lg'>
                    <ViewBoardsIcon className='w-8 h-8' />
                    <p>Planos de Assistência</p>
                </button>
                <button onClick={() => props.unidadesRef.current?.scrollIntoView({ behavior: 'smooth' })} className='flex-1 p-4 flex flex-col gap-4 justify-center items-center shadow rounded-lg bg-gray-100 cursor-pointer transition duration-200 hover:shadow-lg'>
                    <LocationMarkerIcon className='w-8 h-8' />
                    <p>Unidades Próximas</p>
                </button>
                <a href='https://api.whatsapp.com/send?phone=5588997219222' target="_blank" className='flex-1 p-4 flex flex-col gap-4 justify-center items-center shadow rounded-lg bg-gray-100 cursor-pointer transition duration-200 hover:shadow-lg'>
                    <img src={require('../../../assets/whatsapp-icon.png')} alt='Logo PAF' className='w-8 h-8' />
                    <p>Fale conosco no whatsapp</p>
                </a>
                <a href='tel:+55 (88) 9 9721-9222' className='flex-1 p-4 flex flex-col gap-4 justify-center items-center shadow rounded-lg bg-gray-100 cursor-pointer transition duration-200 hover:shadow-lg'>
                    <PhoneIcon className='w-8 h-8' />
                    <p>Fale conosco no telefone</p>
                </a>
            </div>
        </div>
    )
}
