import { useRoutes } from "react-router-dom";
import Home from "../pages/Home";
import LayoutDashboard from "../components/LayoutDashboard";
import QuemSomos from "../pages/QuemSomos";
import Planos from "../pages/Planos";
import Beneficios from "../pages/Beneficios";
import TrabalheConosco from "../pages/TrabalheConosco";

function Router() {
    const routes = [
        {
            path: "/",
            element: <LayoutDashboard />,
            children: [
                {
                    path: "/",
                    element: <Home />,
                },
                {
                    path: "/quem-somos",
                    element: <QuemSomos />,
                },
                {
                    path: "/trabalhe-conosco",
                    element: <TrabalheConosco />,
                },
                {
                    path: "/planos",
                    element: <Planos />,
                },
                {
                    path: "/beneficios",
                    element: <Beneficios />,
                },
            ],
        },
    ];

    return useRoutes(routes);
}

export default Router;
