import React from 'react'
import { ClockIcon, DeviceMobileIcon, MailIcon, LocationMarkerIcon, PhoneIcon, OfficeBuildingIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'

export default function InformacoesEmpresa() {
    return (
        <div className='max-w-5xl mx-auto grid xl:grid-cols-2 grid-cols-1 items-center gap-16 text-left'>
            <div className='flex flex-col'>
                <div className='flex flex-col gap-1'>
                    <div className='flex flex-row items-center space-x-4'>
                        <PhoneIcon className='h-12 p-2 w-auto shrink-0 text-green-900 rounded-full' />
                        <a href="tel:(88) 3611-2400" className='text-green-600 font-semibold cursor-pointer '>(88) 3611-2400</a>
                    </div>
                    <div className='flex flex-row items-center space-x-4'>
                        <LocationMarkerIcon className='h-12 p-2 w-auto shrink-0 text-green-900 rounded-full' />
                        <p href="" target="_blank" className='text-green-600 font-semibold cursor-pointer '>
                            Rua Jânio Quadros, nº 187, Centro, Sobral-CE
                        </p>
                    </div>
                    {/* <div className='flex flex-row items-center space-x-4'>
                        <MailIcon className='h-12 p-2 w-auto shrink-0 text-green-900 rounded-full' />
                        <a href="mailto:" target="_blank" className='text-green-600 font-semibold cursor-pointer break-all'>contato@plasfran.com.br</a>
                    </div> */}
                    <div className='flex flex-row items-center space-x-4'>
                        <DeviceMobileIcon className='h-12 p-2 w-auto shrink-0 text-green-900 rounded-full' />
                        <a href="tel:(88) 9 9721-9222" className='text-green-600 font-semibold cursor-pointer '>(88) 9 9721-9222</a>
                    </div>
                    <div className='flex flex-row items-center space-x-4'>
                        <OfficeBuildingIcon className='h-12 p-2 w-auto shrink-0 text-green-900 rounded-full' />
                        <p className='text-green-600 font-semibold cursor-pointer '>00.471.545/0001-86</p>
                    </div>
                </div>
            </div>


            <div className='flex flex-col gap-4 text-center items-center'>
                <img
                    className="h-auto w-64 object-cover"
                    src={require('../../../assets/logo.png')}
                    alt=""
                />
                <strong className='pl-4 text-lg text-green-800'>
                    PAF o melhor plano funerário da região. Aqui todos se igualam! Vem você também ser PLASFRAN
                </strong>
                <div className='flex flex-row gap-4'>
                    <a href="https://www.facebook.com/plasfran/?ref=page_internal" target="_blank">
                        <svg height="48" fill="darkgreen" className='cursor-pointer p-3 h-16 w-16 object-contain' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" /></svg>
                    </a>
                    <a href="https://www.instagram.com/grupo.plasfran/" target="_blank">
                        <svg height="48" fill="darkgreen" className='cursor-pointer p-3 h-16 w-16 object-contain' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
                    </a>
                </div>
            </div>
        </div>
    )
}
