import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
    MailIcon,
    MenuIcon,
    PhoneIcon,
    XIcon,
} from '@heroicons/react/outline'
import { Link, useNavigate } from 'react-router-dom'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Header(props) {
    const navigate = useNavigate();

    const menuSuperior = [
        {
            name: 'Início',
            action: () => {
                navigate("/")
            },
        },
        {
            name: 'Quem somos',
            action: () => { navigate("/quem-somos") }
        },
        {
            name: 'Serviços',
            action: () => {
                navigate("/")
                setTimeout(() => {
                    props.servicosRef.current?.scrollIntoView({ behavior: 'smooth' })
                }, 500);
            },
        },
        {
            name: 'Planos',
            action: () => {
                navigate("/")
                setTimeout(() => {
                    props.planosRef.current?.scrollIntoView({ behavior: 'smooth' })
                }, 500);
            },
        },
        {
            name: 'Unidades',
            action: () => {
                navigate("/")
                setTimeout(() => {
                    props.unidadesRef.current?.scrollIntoView({ behavior: 'smooth' })
                }, 500);
            },
        },
        // {
        //     name: 'Trabalhe Conosco',
        //     action: () => { navigate("/trabalhe-conosco") }
        // },
        {
            name: 'Contato',
            action: () => {
                navigate("/")
                setTimeout(() => {
                    props.contatoRef.current?.scrollIntoView({ behavior: 'smooth' })
                }, 500);
            },
        },
    ]

    return (
        <Popover className="relative bg-green-600">
            <div className="max-w-5xl mx-auto px-4 sm:px-6">
                <div className="flex justify-between items-center py-6 lg:space-x-10">
                    <div className="flex shrink-0">
                        <Link to="/">
                            <span className="sr-only">Logo</span>
                            <img
                                className="h-12 w-auto"
                                src={require('../../assets/logo.png')}
                                alt=""
                            />
                        </Link>
                    </div>
                    <div className="-mr-2 -my-2 lg:hidden">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                            <span className="sr-only">Abrir menu</span>
                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                    </div>
                    <Popover.Group as="nav" className="hidden lg:flex ">
                        <div className='flex flex-row space-x-4'>
                            {menuSuperior.map((item) => (
                                <button
                                    key={item.name}
                                    onClick={item.action}
                                    className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-white hover:text-green-800"
                                >
                                    {item.name}
                                </button>
                            ))}
                        </div>
                    </Popover.Group>
                </div>
            </div>

            <Transition
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden z-50">
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50 shadow-2xl">
                        <div className="pt-5 pb-6 px-5">
                            <div className="flex items-center justify-between">
                                <div>
                                    <img
                                        className="h-12 w-auto h-20"
                                        src={require('../../assets/logo.png')}
                                        alt="logo"
                                    />
                                </div>
                                <div className="-mr-2">
                                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                        <span className="sr-only">Close menu</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </Popover.Button>
                                </div>
                            </div>
                            <div className="mt-6">
                                <nav className="grid gap-y-8">
                                    {menuSuperior.map((item) => (
                                        <button
                                            key={item.name}
                                            onClick={item.action}
                                            className="-m-3 p-3 flex items-center rounded-md text-green-900 hover:text-green-600 text-base font-medium text-white text-start"
                                            >
                                            {item.name}
                                        </button>
                                    ))}
                                </nav>
                            </div>

                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    )
}