import React, { useEffect, useState } from 'react'

import {
    BrowserRouter,
} from "react-router-dom";
import Router from './router';

function App() {
    return (
        <BrowserRouter>
            <div className='relative h-screen'>
                <Router />
            </div>
        </BrowserRouter>
    );
}

export default App;
