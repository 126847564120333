import React, { useState, useRef, useEffect } from 'react'
import ReactPlayer from 'react-player'
import Carousel from 'react-multi-carousel';
import { CheckCircleIcon } from '@heroicons/react/solid';
import useIsInViewport from '../../components/useIsInViewport'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TrabalheConosco() {
    function importAll(r) {
        return r.keys().map(r);
    }

    const [depoimentos, setDepoimentos] = useState(null);
    const [oportunidades, setOportunidades] = useState(null);

    const imagensBeneficios = importAll(require.context('../../assets/beneficios', false, /\.(png|jpe?g|svg)$/));

    const vantagens = [
        {
            imagem: imagensBeneficios[0],
            texto: 'PAF Total Gratuito (Incluso cônjuges ou companheiro(a) e filhos)',
            ref: useRef(null),
        },
        {
            imagem: imagensBeneficios[1],
            texto: 'Capacitações internas',
            ref: useRef(null),
        },
        {
            imagem: imagensBeneficios[2],
            texto: 'Descontos em profissionais e empresas parceiras',
            ref: useRef(null),
        },
        {
            imagem: imagensBeneficios[3],
            texto: 'Bonificações e premiações',
            ref: useRef(null),
        },
        {
            imagem: imagensBeneficios[4],
            texto: 'Participação nos resultados (comércio)',
            ref: useRef(null),
        },
        {
            imagem: imagensBeneficios[5],
            texto: 'Crédito e descontos nas Óticas, Farmácias e Clínicas próprias',
            ref: useRef(null),
        }
    ]

    const vantagensVisiveis = [
        { visivel: useIsInViewport(vantagens[0].ref) },
        { visivel: useIsInViewport(vantagens[1].ref) },
        { visivel: useIsInViewport(vantagens[2].ref) },
        { visivel: useIsInViewport(vantagens[3].ref) },
        { visivel: useIsInViewport(vantagens[4].ref) },
        { visivel: useIsInViewport(vantagens[5].ref) },
    ]

    const responsiveDepoimentos = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const responsiveOportunidades = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const PrimeiroTexto = () => {
        return (
            <div className='flex-1 flex flex-col gap-4'>
                <p>Desde a nossa fundação, temos como missão ajudar famílias, com soluções de serviços que atendam suas necessidades.</p>
                <p>Mas sabemos que isso só é possível quando apoiamos o desenvolvimento profissional do nosso time e adotamos um ambiente de trabalho colaborativo, inovador, inclusivo, diverso e plural.</p>
            </div>
        )
    }

    const SegundoTexto = () => {
        return (
            <div className='flex-1 flex flex-col gap-4'>
                <p>Seguindo sempre com ética, transparência, responsabilidade e respeito, acreditamos que o crescimento é resultado das oportunidades e conquistas compartilhadas e do potencial inexplorado de cada pessoa.</p>
                <p>Você pode colaborar com esse movimento.</p>
            </div>
        )
    }

    useEffect(() => {
        carregaOportunidades()
        carregaDepoimentos()
    }, [])

    function carregaOportunidades() {
        const imagensOportunidades = importAll(require.context('../../assets/oportunidades', false, /\.(png|jpe?g|svg)$/));

        setOportunidades(imagensOportunidades);
    }

    function carregaDepoimentos() {

        const imagensColaboradores = importAll(require.context('../../assets/colaboradores', false, /\.(png|jpe?g|svg)$/));

        const textosDepoimentos = [
            {
                nome: `Dona Nilda`,
                cargo: "Gerente Cemitérios-parques",
                texto: `Minha história com a Plasfran começou em 2014 e foi assim uma oportunidade muito grande, porque eu já estava há muito tempo sem trabalhar, já tinha trabalhado muito pra mim, mas vi que a Plasfran tinha muitas oportunidades, inclusive de crescimento, como hoje ela cresceu muito e continua crescendo. Então com isso eu sinto um orgulho muito grande de fazer parte dessa empresa. Eu e minha família, todos nós temos a Plasfran no coração, com certeza temos essa empresa como nossa, porque a gente ver que aqui realmente é uma empresa, mas é família, então meu orgulho é muito grande de trabalhar nessa empresa.
                Tem uma história muito bonita entre eu e a Plasfran, comecei aqui, muito assim, de baixo e vi que a Plasfran dava toda essa oportunidade pra a gente crescer dentro da empresa, vi que ela tinha muito potencial, pra crescer, pra ajudar as pessoas, é uma área em que a gente se dedica muito a ajudar as pessoas. Eu me sinto me orgulhosa em fazer parte dessa empresa, em ter crescido com ela, junto com ela, tenho muito orgulho. O que me incentivou muito e que me incentiva a trabalhar justamente até agora, é que ela sempre pensa no crescimento, no crescimento dos seus colaboradores, no nosso crescimento, ela pensa nisso e eu tenho muito, muito mesmo, orgulho de trabalhar aqui e espero que a gente consiga crescer mais ainda.`,
                imagem: imagensColaboradores[0]
            },
            {
                nome: `Silvio Carneiro`,
                cargo: "Atendente Farmácia",
                texto: `Olá, sou Sílvio Carneiro “Sobrinho”? Sou colaborador do Grupo Plasfran, faço parte da unidade da Farmácia, tenho satisfação de trabalhar neste Grupo, por ser uma empresa conceituada no comércio de Sobral. Gosto muito de trabalhar na Plasfran, porque aqui posso crescer na empresa e fazendo um trabalho com muita responsabilidade e competência, para que a empresa cresça e eu cresça na empresa também.`,
                imagem: imagensColaboradores[1]
            },
            {
                nome: `Paulo Sousa`,
                cargo: "Consultor de Vendas Externas",
                texto: `Olá amigos, me chamo Paulo Sousa, sou consultor do PAF, Plano de Assistência familiar da Plasfran. Cheguei na Plasfran em 2019 e de lá pra cá mudou muita coisa na minha vida, e eu tenho encontrado aqui na Plasfran, oportunidades, que em outras empresas eu não tive. É uma empresa que se preocupa muito com o seu colaborador e é uma empresa que está sempre inovando, isso faz com que a gente crença muto profissionalmente e aqui você tem o melhor, um Plano que dar assistência tanto pra você, como pra sua família. Eu sempre digo pra todos que estão chegando, que o Grupo Plasfran é um mar de oportunidades, você só precisa saber nadar. Quando a gente bate as metas, a bonificação é bem superior ao salário-mínimo. Eu já vinha há algum tempo desempregado, tinha passado por situações, vamos dizer assim, endividado, mas quando eu comecei a trabalhar aqui, tudo mudou, hoje estou estabilizado financeiramente, consegui comprar um transporte, o que eu ganho aqui dar pra sustentar eu, a minha casa e minha família. Uma empresa bem estruturada, a mais de 30 a anos no mercado, aqui a gente encontra oportunidades pra crescer, profissionalmente e financeiramente.
                `,
                imagem: imagensColaboradores[2]
            },
            {
                nome: `Aurenice Carvalho`,
                cargo: "Gerente Óticas Plasfran",
                texto: `A Plasfran acredita no colaborador e nas suas infinitas possibilidades de crescer, de se expandir, de desbravar novos caminhos. À frente das Óticas pude ver um projeto profissional, idealizado por mim, abraçado e incentivado por todos, principalmente pela Diretoria, que nos permitem alçar voos mais altos.
                `,
                imagem: imagensColaboradores[3]
            },
            {
                nome: `Luciane`,
                cargo: "Posto de Coleta",
                texto: `Fazer parte do Grupo Plasfran me traz uma gratidão imensa. Aqui somos desafiados a cada dia para sermos melhores, vencer obstáculos, buscar sonhos e ter coragem para enfrentar qualquer batalha. Além de acreditar na cultura e nos valores da empresa, tenho muita admiração e orgulho das pessoas que trabalham comigo. O sentimento que juntos iremos mais longe é o que alimenta a minha paixão pelo que faço e por todo o sucesso que estamos construindo..
                `,
                imagem: imagensColaboradores[4]
            },
        ]

        setDepoimentos(textosDepoimentos);
    }

    const CustomRightArrow = ({ onClick, ...rest }) => {
        const {
            onMove,
            carouselState: { currentSlide, deviceType }
        } = rest;
        // onMove means if dragging or swiping in progress.
        return <button className='w-16 h-16 bg-red-600' onClick={() => onClick()} >
            Voltar
        </button>;
    };

    const CustomLeftArrow = ({ onClick, ...rest }) => {
        const {
            onMove,
            carouselState: { currentSlide, deviceType }
        } = rest;
        // onMove means if dragging or swiping in progress.
        return <button className='w-16 h-16 bg-red-600' onClick={() => onClick()} >
            Voltar
        </button>;
    };

    return (
        <main className="text-center scroll-smooth w-full h-full bg-fixed bg-contain bg-center bg-green-50">

            <div
                style={{
                    backgroundImage: `url("${require("../../assets/fotos/colaboradores.jpg")}")`, backgroundPosition: 'center'
                }}>
                <div className='bg-gradient-to-b from-green-600 to-transparent bg-green-600/40'>

                    <section className='max-w-5xl mx-auto  flex flex-col gap-8 h-fit w-full py-16 px-8 text-white'>
                        <h1 className='w-fit text-4xl font-bold text-left break-all border-b-4 border-lime-400 pb-4'>
                            #SomosTodosPlasfran
                        </h1>

                        <div className="text-xl flex flex-col gap-16 text-left">
                            <div className='lg:flex flex-row items-start gap-4'>
                                <PrimeiroTexto />
                                <SegundoTexto />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <section className='max-w-5xl mx-auto  flex flex-col gap-8 h-fit w-full py-16 px-8 text-green-900'>
                <h1 className='w-fit text-4xl font-bold text-left  border-b-4 border-lime-400 pb-4'>
                    Desenvolvimento Humano
                </h1>

                <div className="text-xl flex flex-col gap-16 text-left">
                    <p>Reconhecemos que colaboradores engajados possuem um alto nível de proatividade e dedicação com o sucesso da empresa, além de se esforçarem para compreender e implementar nossas práticas e políticas internas, desenvolvendo melhorias contínuas para o alcançar de resultados , e comprometem-se para atender às expectativas dos clientes associados.</p>

                    <p>
                        O desenvolvimento humano é constituído e impulsionado por 3 dimensões:
                        <ul className='flex-1 flex flex-col gap-4'>
                            <li className='flex gap-2'> <CheckCircleIcon className='mt-2 w-4 h-4 shrink-0' /> <strong>Pensar: </strong> o colaborador compreende e incorpora objetivos, valores e estratégias da empresa</li>
                            <li className='flex gap-2'> <CheckCircleIcon className='mt-2 w-4 h-4 shrink-0' /> <strong>Sentir: </strong> o colaborador sente orgulho da empresa e da importância da atividade que desempenha;</li>
                            <li className='flex gap-2'> <CheckCircleIcon className='mt-2 w-4 h-4 shrink-0' /> <strong>Agir: </strong> o colaborador acredita e se sente bem em trabalhar na empresa, fazendo o necessário para que o negócio tenha sucesso.</li>
                        </ul>
                    </p>

                    <div className='flex flex-col lg:flex-row items-start gap-12 text-green-600'>
                        <div className='flex-1 flex flex-col gap-2'>
                            <p className='text-lg font-semibold h-12'>Programa de Integração e Engajamento</p>
                            <p className='text-2xl font-bold'>Somos Todos Plasfran</p>
                            <p>O Programa de Integração e Engajamento Somos Todos Plasfran incentiva os colaboradores a tomarem medidas e estarem ativos e comprometidos no trabalho, melhorando assim os objetivos pessoais e profissionais, englobando programas de treinamento e autodesenvolvimento, premiação por metas alcançadas e por produtividade, feedbacks regulares, entre outros, fazendo a diferença na satisfação e no desempenho dos profissionais.</p>
                        </div>
                        <div className='flex-1 flex flex-col gap-2'>
                            <p className='text-lg font-semibold h-12'>Programa de Alfabetização</p>
                            <p className='text-2xl font-bold'>Alfabetiza Plasfran</p>
                            <p>
                                O Programa Alfabetiza Plasfran tem como principais objetivos:
                                <ul className='flex-1 flex flex-col gap-4'>
                                    <li className='flex gap-2'> <CheckCircleIcon className='mt-2 w-4 h-4 shrink-0' /> Alfabetizar colaboradores, possibilitando-lhes condições para prosseguir e/ou concluir sua formação;</li>
                                    <li className='flex gap-2'> <CheckCircleIcon className='mt-2 w-4 h-4 shrink-0' /> Promover o desenvolvimento do ser humano, como agente transformador, tomando posse da verdadeira cidadania;</li>
                                    <li className='flex gap-2'> <CheckCircleIcon className='mt-2 w-4 h-4 shrink-0' /> Garantir não só o acesso, como também a permanência dos alunos alfabetizados, através do resgate da auto-estima.</li>
                                </ul>
                            </p>
                        </div>
                        <div className='flex-1 flex flex-col gap-2'>
                            <p className='text-lg font-semibold h-12'>Programa de Desenvolvimento e Carreiras</p>
                            <p className='text-2xl font-bold'>Talentos Plasfran</p>
                            <p>
                                O Programa de Desenvolvimento e Carreiras Talentos Plasfran tem como principais objetivos:
                                <ul className='flex-1 flex flex-col gap-4'>
                                    <li className='flex gap-2'> <CheckCircleIcon className='mt-2 w-4 h-4 shrink-0' /> Conhecer todos colaboradores com relação ao grau de instrução, área de formação e expectativas quanto a carreira;</li>
                                    <li className='flex gap-2'> <CheckCircleIcon className='mt-2 w-4 h-4 shrink-0' />  Identificar cargos e setores que enquadram-se a cada colaborador;</li>
                                    <li className='flex gap-2'> <CheckCircleIcon className='mt-2 w-4 h-4 shrink-0' />  Auxiliar o desenvolvimento da carreira profissional do colaborador dentro do Grupo Plasfran.</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className={classNames(
                'max-w-6xl mx-auto flex flex-col-reverse items-center lg:flex-row gap-8 h-fit w-full py-16 px-8 text-green-900',
            )}>
                <div className='w-fit flex flex-col items-end justify-start text-2xl z-20'>
                    <div className='w-fit h-full flex flex-col items-end justify-start gap-2 px-8 py-4 pb-8 m-4 rounded-2xl bg-green-600 text-end font-bold shadow-lg'>
                        <p className='' >Incentivar e valorizar o </p>
                        <p className='' ><strong className='bg-green-500 text-white px-2 rounded'>autodesenvolvimento</strong> e a busca de</p>
                        <p className='' ><strong className='bg-green-500 text-white px-2 rounded'>novos conhecimentos</strong></p>
                    </div>
                    <div className='px-12 text-end'>
                        <p className='font-bold italic'>Francisco Antônio Vieira de Araújo</p>
                        <p className='font-semibold'>CEO Grupo Plasfran</p>
                    </div>
                </div>
                <div className='flex flex-row items-center justify-center w-full flex-1 z-10 -mb-16 lg:-ml-16 -my-4 overflow-hidden rounded'>
                    <ReactPlayer
                        style={{ width: '100%', height: '100%' }}
                        url='https://www.youtube.com/watch?v=70uwOKmCfLs' />
                </div>
            </section>

            <section className={classNames(
                ' flex-col-reverse lg:flex gap-8 h-fit w-full text-green-900 bg-green-500 relative p-4',
            )}>
                <div className='absolute top-0 left-0 x-10 bg-green-50 h-24 w-full'></div>

                {depoimentos &&
                    <Carousel
                        swipeable={true}
                        draggable={false}
                        autoPlay={false}
                        responsive={responsiveDepoimentos}
                        className='h-full'
                        infinite={true}
                        showDots={true}
                        customTransition="all 3s"
                        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                        customRightArrow={<CustomRightArrow />}
                        customLeftArrow={<CustomLeftArrow />}
                    >
                        {depoimentos.map((depoimento, index) => (
                            <div key={index} className='flex lg:flex-row flex-col h-full pt-12 px-12 items-center justify-center'>
                                <img className="self-center z-20 lg:w-1/4 w-96 aspect-square shadow-lg object-contain bg-gray-100 rounded-full -mt-12" src={depoimento.imagem} alt={`Slide ${index + 1}`} />
                                <div className='flex flex-col lg:w-1/2 items-start gap-2 px-0 lg:px-12 py-12 text-left'>
                                    <p className='text-2xl font-bold'>{depoimento.nome}</p>
                                    <p className='text-2xl font-semibold'>{depoimento.cargo}</p>
                                    <p className=''>{depoimento.texto}</p>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                }
            </section>

            <section className='bg-green-800  px-4'>
                <div className='max-w-5xl mx-auto flex flex-col gap-8 h-fit w-full pt-16 text-white'>
                    <h1 className='text-4xl font-bold text-left'>
                        Benefícios exclusivos para colaboradores
                    </h1>
                </div>

                <section className='max-w-5xl mx-auto py-16 grid grid-cols-1 lg:grid-cols-3 gap-16 w-full items-center'>

                    {vantagens.map((vantagem, index) => (
                        <div
                            ref={vantagem.ref}
                            key={index}
                            className={classNames(
                                vantagensVisiveis[index].visivel ? "animate-fromBottom visible" : "invisible",
                                'flex flex-row gap-4 items-center transition duration-200 cursor-default'
                            )}
                        >
                            <div className='shadow w-24 h-24 aspect-square bg-yellow-500 rounded-full p-6 justify-self-center flex items-center jsutify-center'>
                                <img className='object-contain w-full h-full' src={vantagem.imagem} alt={vantagem.texto} />
                            </div>

                            <h2 className='text-lg text-yellow-500 font-bold text-left'>{vantagem.texto}</h2>

                        </div>
                    ))}
                </section>
            </section>


            <section className={classNames(
                'flex-col lg:flex gap-8 h-fit w-full text-green-900 relative p-4',
            )}>

                <div className='max-w-5xl mx-auto flex flex-col gap-8 h-fit w-full pt-16 text-green-900'>
                    <h1 className='w-fit text-4xl font-bold text-left  border-b-4 border-lime-400 pb-4'>
                        Oportunidades
                    </h1>
                </div>

                <div>
                    {oportunidades &&
                        <Carousel
                            swipeable={true}
                            draggable={false}
                            autoPlay={false}
                            responsive={responsiveOportunidades}
                            className='h-full py-12'
                            infinite={true}
                            showDots={true}
                            customTransition="all 3s"
                            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                            customRightArrow={<CustomRightArrow />}
                            customLeftArrow={<CustomLeftArrow />}
                        >
                            {oportunidades.map((oportunidade, index) => (
                                <div key={index} className='flex lg:flex-row flex-col h-full pt-12 px-12 max-w-5xl mx-auto '>
                                    <img className="self-center z-20 w-96 aspect-square shadow-lg object-contain bg-gray-100 -mt-12" src={oportunidade} alt={`Slide ${index + 1}`} />
                                </div>
                            ))}
                        </Carousel>
                    }
                </div>
            </section>

            <section
                style={{
                    backgroundImage: `url("${require("../../assets/fotos/FOTO_INAUGURACAO_FARMACIA.jpg")}")`, backgroundPosition: '0% 100%', backgroundSize: '100%'
                }}>
                <div className='bg-gradient-to-t from-green-600 to-transparent bg-green-600/40'>
                    <div className='max-w-5xl mx-auto  flex flex-col gap-8 h-fit w-full px-8 text-green-100 pb-16 pt-96'>
                        <div className='flex flex-col lg:flex-row items-center justify-center gap-8'>
                            <div className='w-1/2 text-end relative'>
                                <h1 className='text-6xl' style={{ fontFamily: 'Freehand' }}>
                                    Venha fazer parte!
                                </h1>
                                <span className='absolute -top-24 right-0 w-24 h-24 rotate-12'
                                    style={{
                                        backgroundImage: `url("${require("../../assets/chat.png")}")`, backgroundPosition: '0% 100%', backgroundSize: '100%'
                                    }}>
                                    <span className='absolute -top-2 right-2 w-20 h-20 -rotate-12'
                                        style={{
                                            backgroundImage: `url("${require("../../assets/smile.png")}")`, backgroundPosition: '0% 100%', backgroundSize: '100%'
                                        }}>
                                    </span>
                                </span>
                            </div>
                            {/* <span className='bg-white lg:h-32 lg:w-1 h-1 w-full'></span>
                            <a href='/trabalhe-conosco/curriculo' className='text-4xl text-left w-1/2 hover:-translate-y-2 hover:text-white transition duration-300'>
                                <p>Clique aqui para cadastrar</p>
                                <p className='font-bold'>seu currículo</p>
                            </a> */}
                        </div>
                    </div>
                </div>
            </section>
        </main >
    )
}
