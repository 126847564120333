import React from 'react';
import { useEffect } from 'react';

function Beneficios() {
    useEffect(() => {
        window.location.href = 'https://www.instagram.com/s/aGlnaGxpZ2h0OjE3OTYwNTIzNDcwNTU5OTk5?story_media_id=3136038053462171300&igshid=NTc4MTIwNjQ2YQ==';
    }, [])

  return <div />;
}

export default Beneficios;