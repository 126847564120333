import React, { useRef } from 'react'
import AcessoRapido from './SessoesHome/AcessoRapido';
import EmpresasGrupo from './SessoesHome/EmpresasGrupo';
import Paf from './SessoesHome/Paf';
import Servicos from './SessoesHome/Servicos';
import UnidadesEmpresa from './SessoesHome/UnidadesEmpresa';
import { useOutletContext } from "react-router-dom";
import MapChart from "../../components/MapChart";

export default function Home() {
    const [
        acessoRapidoRef,
        servicosRef,
        planosRef,
        unidadesRef,
    ] = useOutletContext();

    return (
        <main
            className="bg-green-50 text-center scroll-smooth w-full h-full bg-fixed bg-contain bg-center "
            style={{ backgroundImage: `url(${require('../../assets/fundo/fundo-site.png')})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <section ref={acessoRapidoRef} className='h-fit w-full pb-16'>
                <AcessoRapido planosRef={planosRef} unidadesRef={unidadesRef} />
            </section>
            <section ref={servicosRef} className='h-fit w-full py-16 px-8'>
                <Servicos />
            </section>
            <section ref={planosRef} className='h-fit w-full py-16 px-8 bg-gradient-to-bl from-transparent to-green-50'>
                <Paf />
            </section>
            <section className='h-fit w-full py-16 px-8 bg-gradient-to-tl from-transparent to-green-50'>
                <EmpresasGrupo />
            </section>
            <section ref={unidadesRef} className='h-fit w-full py-16 px-8'>
                <UnidadesEmpresa />
            </section>
            {/* <section className='h-full w-full py-16 px-8'>
                <MapChart />
            </section> */}
        </main>
    )
}
