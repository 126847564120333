import { EyeIcon, ScaleIcon, StarIcon, TrendingUpIcon } from '@heroicons/react/outline'
import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import TimeLine from "../../components/TimeLine";
import useIsInViewport from '../../components/useIsInViewport';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function QuemSomos() {
    const imgNegocioRef = useRef(null);
    const imgClienteRef = useRef(null);
    const imgSucessoRef = useRef(null);

    const imgNegocioIsInViewport = useIsInViewport(imgNegocioRef);
    const imgClienteIsInViewport = useIsInViewport(imgClienteRef);
    const imgSucessoIsInViewport = useIsInViewport(imgSucessoRef);

    const missaoRef = useRef(null);
    // const visaoRef = useRef(null);
    const valoresRef = useRef(null);

    const missaoIsInViewport = useIsInViewport(missaoRef);
    // const visaoIsInViewport = useIsInViewport(visaoRef);
    const valoresIsInViewport = useIsInViewport(valoresRef);

    const PrimeiroTexto = () => {
        return (
            <div className='flex-1 flex flex-col gap-4'>
                <p><strong className="font-semibold text-green-900">Grupo PLASFRAN</strong>, fundado em meados de março de 1990, em Camocim-CE, com o intuito de suprir as necessidades e tornar acessíveis serviços funerários com disponibilidade e qualidade a pessoas e famílias com em dificuldades financeiras e/ou impossibilitadas pelo prazo. Desde a sua idealização, essa história sempre foi apoiada em valores sólidos e fundamentais.</p>
                <p>Desde os primeiros momentos, o <strong className="font-semibold text-green-900">Grupo PLASFRAN</strong> tem primado por oferecer produtos e serviços que permitam à todas as classes sociais possibilidade de adquirirem um serviço funeral completo, através do inovador <strong className='text-green-600'>Plano de Assistência Familiar (PAF)</strong>.</p>
            </div>
        )
    }

    const SegundoTexto = () => {
        return (
            <div className='flex-1 flex flex-col gap-4'>

                <p>Desde os primeiros momentos, o <strong className="font-semibold text-green-900">Grupo PLASFRAN</strong> tem primado por oferecer produtos e serviços que permitam à todas as classes sociais possibilidade de adquirirem um serviço funeral completo, através do inovador <strong className='text-green-600'>Plano de Assistência Familiar (PAF).</strong></p>
                <p>Hoje dispõe de um catálogo de produtos e serviços diversificados, dispostos em estruturas, serviços e produção próprios, com a maior rede de profissionais e empresas conveniadas, com condições e descontos exclusivos e melhor custo-benefício do mercado.</p>
                <p>O <strong className="font-semibold text-green-900">Grupo PLASFRAN</strong> busca inúmeras maneiras de inovar continuamente, seja em produtos ou serviços, com o conceito de inovar no que faz e acredita que, cada entrega é um presente e acalento que o <strong className="font-semibold text-green-900">Grupo PLASFRAN</strong> oferece aos seus clientes.</p>
            </div>
        )
    }

    const TerceiroTexto = () => {
        return (
            <div className='flex-1 flex flex-col gap-4'>
                <p>
                    Aqui acreditamos que cuidado e atenção são importante desde o nascimento até o momento da partida. Então nosso negócio é agregar cuidados com qualidade, agilidade e conforto para fornecer o melhor atendimento aos nossos clientes. Para isso, contamos com um vasto grupo que fornecem o mais variado serviços com vários profissionais capacitados para cuidar de você e sua família.
                </p>
                <p>
                    O <strong className="font-semibold text-green-900">Grupo PLASFRAN</strong> hoje no mercado fornece diversos serviços tais como clínicas médicas, odontologica, fisioterapia, farmácias, óticas, equipamento para convalescentes, serviços funerários e muito mais, além de contar com ótimos preços e benefícios. Isso faz o grupo ser a empresa mais completa em serviços de assistência familiar da região.
                </p>
                <p>
                    Atualmente nossa sede principal encontra localizada no centro da cidade de <strong className="font-semibold text-green-900">Sobral</strong>, com diversas unidades nas cidades de <strong className="font-semibold text-green-900">Itapipoca, Camocim, Tianguá, Parnaíba e Massapê.</strong> Também contamos com filiais nas cidades de <strong className="font-semibold text-green-900"> Acaraú, Alcântaras, Cariré, Cruz, Forquilha, Groaíras, Itarema, Jijoca, Meruoca, Rafael Arruda, Santa Quitéria, Santana do Acaraú, Taperuaba, Ubaúna e Varjota.</strong>
                </p>
            </div>
        )
    }

    const [eventos, setEventos] = useState([
        {
            imagem: "FUNERARIA",
            data: "1990",
            descricao: "O negócio familiar entre irmãos iniciou na cidade de Camocim-CE com a venda porta-a-porta dos esquifes (caixões).​",
        },
        {
            imagem: null,
            data: "1991",
            descricao: "O Grupo PLASFRAN apresentava para os clientes os inovadores Planos Familiares, dando a todas as classes sociais a possibilidade de adquirirem um  serviço funeral completo.​",
        },
        {
            imagem: null,
            data: "2000",
            descricao: "A empresa passou por grandes transformações, cada irmão passou a assumir mercados distintos e inaugurar novas filiais com equipes próprias e independentes.​",
        },
        {
            imagem: "PLASFRAN",
            data: "2001",
            descricao: "O Sr. Francisco Antônio Vieira assume a gestão do Grupo PLASFRAN na cidade de Sobral.​",
        },
        {
            imagem: "LOGO JARDIM ETERNO",
            data: "2005",
            descricao: "Inauguração do cemitério-parque Jardim Eterno.​​"
        },
        {
            imagem: "CONSTRUTORA",
            data: "2009",
            descricao: "Ampliando a área de atuação, o grupo inaugura a Construtora Viva.​"
        },
        {
            imagem: "JARDIM DA PAZ",
            data: "2010",
            descricao: "Aquisição do cemitério-parque Jardim da Paz, tornando-se monopólio na cidade de Sobral.​"
        },
        {
            imagem: "LOGO OTICA",
            data: "2012",
            descricao: "Inauguração da primeira Ótica PLASFRAN, dando início ao segmento de assistência familiar.​​",
        },
        {
            imagem: null,
            data: "2015",
            descricao: "Inicia-se a fabricação de materiais ortopédicos.​​",
        },
        {
            imagem: "CENTRO CLINICO",
            data: "2016",
            descricao: "O grupo inaugura o sonhado Centro Clínico PLASFRAN, uma clínica popular com consultas e exames médicos com até 50% de desconto em comparação ao preço de mercado.​​​",
        },
        {
            imagem: "FARMACIA",
            data: "2016",
            descricao: "Inauguração da primeira Farmácia PLASFRAN.",
        },
    ])

    return (
        <main className="text-center scroll-smooth w-full h-full bg-fixed bg-contain bg-center bg-green-50">
            <section className='max-w-5xl mx-auto  flex flex-col gap-8 h-fit w-full py-16 px-8'>
                <h1 className='text-4xl text-green-900 font-bold'>
                    A Plasfran
                </h1>

                <div className="text-xl flex flex-col gap-16 text-left">
                    <div className='lg:flex flex-row items-center gap-4'>
                        <PrimeiroTexto />
                        <div ref={imgNegocioRef}
                            className={classNames(imgNegocioIsInViewport ? 'animate-fromRight visible' : 'invisible', 'h-80 lg:w-80 w-full rounded-full')}>
                            <img src={require('../../assets/desenhos/negocio.png')} alt="logo-plasfran" className='h-full w-full object-contain' />
                        </div>
                    </div>
                    <div className='lg:flex flex-row items-center gap-4'>
                        <div ref={imgClienteRef}
                            className={classNames(imgClienteIsInViewport ? 'animate-fromLeft visible' : 'invisible', 'h-80 lg:w-80 w-full bg-gren-100 rounded-full')}>
                            <img src={require('../../assets/desenhos/cliente.png')} alt="logo-plasfran" className='h-full w-full object-contain' />
                        </div>
                        <SegundoTexto />
                    </div>
                </div>
            </section>

            <section className='max-w-5xl mx-auto  flex flex-col gap-8 h-fit w-full py-16 px-8'>
                <h1 className='text-4xl text-green-900 font-bold'>
                    O Negócio
                </h1>
                <div className="text-xl flex flex-col gap-4 text-left">
                    <div className='lg:flex flex-row items-center gap-4'>
                        <TerceiroTexto />
                        <div ref={imgSucessoRef}
                            className={classNames(imgSucessoIsInViewport ? 'animate-fromRight visible' : 'invisible', 'h-80 lg:w-80 rounded-full')}>
                            <img src={require('../../assets/desenhos/sucesso.png')} alt="logo-plasfran" className='h-full w-full object-contain' />
                        </div>
                    </div>
                </div>
            </section>

            {/* LINK PARA A PÁGINA DE CONVENIADOS */}
            {/* <Link to="/parceiros" className='text-lg text-green-900'>
                <p>Conheça todos os nossos parceiros e veja qual o mesmo próximo de você</p>
                <span className='font-bold text-green-600'>Saiba mais</span>
            </Link> */}

            <section className='max-w-5xl mx-auto  flex flex-col gap-8 h-fit w-full py-16 px-8'>
                <h1 className='text-4xl text-green-900 font-bold'>
                    Identidade Corporativa
                </h1>
                <div className='max-w-2xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-4 py-4 w-full items-center text-white'>

                    <div ref={missaoRef}
                        className={classNames(
                            missaoIsInViewport ? 'animate-fromRight visible' : 'invisible',
                            'rounded-tr-[70px] rounded-bl-[70px] bg-green-900 flex flex-col gap-8 items-center h-full shadow-lg hover:shadow-2xl transition duration-200 cursor-default p-12')}>
                        <div className='relative text-3xl font-bold'>
                            <h3>Missão</h3>
                        </div>
                        <div className='flex text-lg flex-col gap-4 flex-1 justify-center font-semibold'>
                            <p>
                                A PLASFRAN ajuda pessoas com dificuldade de acesso à serviços de saúde (financeiro e tempo) e reduz o sofrimento financeiro e logístico na hora da perda de um parente querido, através da oferta de uma estrutura enxuta e eficiente de serviços de saúde e assistência funerária, tudo em um só lugar
                            </p>
                        </div>
                    </div>

                    {/* <div ref={visaoRef}
                        className={classNames(
                            visaoIsInViewport ? 'animate-fromBottom visible' : 'invisible',
                            'rounded-tr-[70px] rounded-bl-[70px] flex flex-col gap-8 items-center h-full shadow-lg hover:shadow-2xl transition duration-200 cursor-default bg-green-600 p-12')}>
                        <div className='relative text-3xl text-white font-bold'>
                            <h3>Visão</h3>
                        </div>
                        <div className='text-gray-200 flex text-lg flex-col gap-4 flex-1 justify-center font-semibold'>
                            <p className='mb-12'>Crescer o resultado líquido (lucro) em 5x.</p>
                        </div>
                    </div> */}

                    <div ref={valoresRef}
                        className={classNames(
                            valoresIsInViewport ? 'animate-fromLeft visible' : 'invisible',
                            'rounded-tr-[70px] rounded-bl-[70px] bg-green-400 flex flex-col gap-8 items-center h-full shadow-lg hover:shadow-2xl transition duration-200 cursor-default p-12')}>
                        <div className='relative text-3xl font-bold'>
                            <h3>Valores</h3>
                        </div>
                        <div className='flex text-lg flex-col gap-4 flex-1 justify-center font-semibold'>
                            <p>Respeito</p>
                            <p>Honestidade</p>
                            <p>Comprometimento</p>
                            <p>Meritocracia</p>
                            <p>Foco no cliente</p>
                            <p>Exemplo</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='max-w-3xl mx-auto  flex flex-col gap-8 h-fit w-full py-16 px-8'>
                <h1 className='text-4xl text-green-900 font-bold'>
                    DNA PLASFRAN
                </h1>
                <img src={require('../../assets/piramide.png')} alt="logo-plasfran" className='h-full w-full object-contain' />
            </section>

            {/* <section className='max-w-5xl mx-auto  h-fit w-full md:pt-16 px-8'>
                <TimeLine eventos={eventos} />
            </section> */}
        </main>
    )
}
