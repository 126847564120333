import React, { useEffect, useRef } from 'react'
import { BadgeCheckIcon, OfficeBuildingIcon, ShieldCheckIcon } from '@heroicons/react/solid'
import useIsInViewport from '../../../components/useIsInViewport'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Servicos() {
    const servicos = [
        {
            icone: "oculos-icon",
            titulo: 'Ótica',
            descricao: 'Nossa ótica conta com toda a qualidade na hora de realizar seu exame. Comprando sua armação em nossa ótica, a consulta é GRATUITA!',
            ref: useRef(null),
        },
        {
            icone: "copo-icon",
            titulo: 'Farmácia',
            descricao: 'Associados Plasfran tem acesso ilimitados a descontos em todas as nossas drogarias',
            ref: useRef(null),
        },
        {
            icone: "pessoa-icon",
            titulo: 'Material Ortopédico',
            descricao: 'Aqui damos amparo na sua recuperação, oferecendo empréstimo de materiais ortopedicos',
            ref: useRef(null),
        },
        {
            icone: "familia-icon",
            titulo: 'Plano de Assistência Familiar',
            descricao: 'Na Plasfran protegemos você e toda sua família com inúmeros benefícios e mensalidades que cabem no seu bolso',
            ref: useRef(null),
        },
        {
            icone: "casa-icon",
            titulo: 'Clínica',
            descricao: 'Em um único lugar, diversas especialidades para cuidar da sua saúde',
            ref: useRef(null),
        },
        {
            icone: "caixao-icon",
            titulo: 'Serviços Funerários',
            descricao: 'Oferecendo serviço funerário especial e especializado para proporcionar tranquilidade e o amparo necessário para você e sua família',
            ref: useRef(null),
        },
        {
            icone: "lapide-icon",
            titulo: 'Cemitério',
            descricao: 'Cemitérios, parques e salões de velório. Vendas de Jazigos e locação de salões para particular. Venha conhever nosso cemitério Jardim Eterno',
            ref: useRef(null),
        },
        {
            icone: "carro-icon",
            titulo: 'Construtora',
            descricao: 'Locação de máquinas para obras de terraplanagem',
            ref: useRef(null),
        },
    ]

    const servicosVisiveis = [
        { visivel: useIsInViewport(servicos[0].ref) },
        { visivel: useIsInViewport(servicos[1].ref) },
        { visivel: useIsInViewport(servicos[2].ref) },
        { visivel: useIsInViewport(servicos[3].ref) },
        { visivel: useIsInViewport(servicos[4].ref) },
        { visivel: useIsInViewport(servicos[5].ref) },
        { visivel: useIsInViewport(servicos[6].ref) },
        { visivel: useIsInViewport(servicos[7].ref) },
    ]

    return (
        <div className='max-w-5xl mx-auto'>

            <div className='flex flex-col items-center py-4 gap-4'>
                <h1 className='text-4xl text-green-900 font-bold'>Conheça os melhores serviços de Assistência Familiar </h1>
                <p className='text-lg text-green-900'>
                    Aqui na PLASRAN você garante qualidade e garantia em todos os produtos e serviços especializados. Além de agilidade e disponibilidade no atendimento por uma equipe composta por grandes profissionais
                </p>
            </div>

            <div className='grid grid-cols-1 lg:grid-cols-3 gap-16 pt-32 w-full items-center'>
                {servicos.map((servico, index) => (
                    <div
                        ref={servico.ref}
                        key={index}
                        className={classNames(
                            servicosVisiveis[index].visivel ? "animate-fromBottom visible" : "invisible",
                            'hover:bg-green-50 hover:border-green-600 bg-green-200 hover:shadow-2xl',
                            'border relative grid grid-cols-1 items-start gap-4 h-full rounded-lg p-8 pt-16 shadow-lg transition duration-200 cursor-default'
                        )}
                    >
                        <div className='shadow w-20 h-20 bg-green-600 rounded-full p-6 absolute justify-self-center -mt-10 flex items-center jsutify-center'>
                            <img className='filter grayscale contrast-200 invert object-contain w-full h-full' src={require(`../../../assets/servicos/${servico.icone}.png`)} alt={servico.titulo} />
                        </div>

                        <h2 className='text-2xl text-green-800 font-bold'>{servico.titulo}</h2>
                        <p className='text-lg text-green-900 font-semibold'>{servico.descricao}</p>
                    </div>
                ))}
            </div>

        </div>
    )
}
