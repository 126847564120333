import React, { useEffect, useState } from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 6
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export default function EmpresasGrupo() {

    

    const [imagens, setImagens] = useState(null);

    function importAll(r) {
        return r.keys().map(r);
    }
    useEffect(() => {
        const empresas = importAll(require.context('../../../assets/empresas', false, /\.(png|jpe?g|svg)$/));
        setImagens(empresas);
    }, [])

    return (
        <div className='h-44'>
            {imagens &&
                <Carousel
                    swipeable={true}
                    draggable={false}
                    autoPlay={true}
                    responsive={responsive}
                    className='h-full w-full'
                    infinite={true}
                    customTransition="all 3s"
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                >
                    {imagens.map((imagem, index) => (
                        <img key={index} className="w-full h-32 object-contain" src={imagem} alt={`Slide ${index + 1}`} />
                    ))}
                </Carousel>
            }
        </div>
    )
}
