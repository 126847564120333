import React, { useRef } from 'react'
import { BadgeCheckIcon, OfficeBuildingIcon, ShieldCheckIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'
import useIsInViewport from '../../../components/useIsInViewport';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Paf() {
    const pafRef = useRef(null);
    const planosVisiveis = useIsInViewport(pafRef);

    const beneficionsEcon = [
        "Titular, cônjuge ou companheiro (a), filhos, sem limite de número, idade e estado civil",
        "4 dependentes extras de escolha do titular, sem custo adicional",
        "Inclusão ilimitada de dependentes especiais de escolha do titular",
        "Cobertura parcial na clínica médica e odontológica",
        "Cobertura parcial na assistência funerária",
    ]
    const beneficionsTotal = [
        "Titular, cônjuge ou companheiro (a), filhos, sem limite de número, idade e estado civil",
        "2 dependentes extras de escolha do titular, sem custo adicional",
        "Inclusão ilimitada de dependentes especiais de escolha do titular ",
        "Cobertura parcial na clínica médica e odontológica",
        "Cobertura total na assistência funerária",
        "Assistência jurídica previdenciária e fúnebre",
    ]
    const beneficionsEmpresarial = [
        "Colaborador contratante de empresa associada",
        "Cobertura parcial na clínica médica e odontológica",
        "Cobertura total na assistência funerária",
    ]

    return (
        <div className='max-w-5xl mx-auto pb-8'>
            <div className='flex flex-col items-center pb-16 gap-4'>
                <h1 className='text-4xl text-green-900 font-bold'>Nossos Planos</h1>
                <p className='text-lg text-green-900'>
                    <strong className='font-normal'>Conheça nosso PAF: Plano de Assistência Familiar. </strong>
                    Tenha acesso facilitado a
                    <strong className='text-green-600 font-bold text-2xl mx-1'>
                        consultadas médicas,
                        odontologia,
                        fisioterapia,
                        farmácias,
                        óticas e
                        muitos outros benefícios
                    </strong>
                    para você e sua família. Aqui você encontra o plano ideial
                </p>
            </div>


            <div ref={pafRef} className='grid grid-cols-1 lg:grid-cols-3 gap-0 py-4 lg:h-[800px] w-full items-center '>

                <div className={classNames(planosVisiveis ? 'animate-fromLeft visible' : "invisible", 'bg-gradient-to-r from-green-300 to-green-400 flex flex-col gap-8 items-center lg:min-h-[700px] shadow-lg hover:shadow-2xl transition duration-200 cursor-default p-12')}>
                    <BadgeCheckIcon className='top-0 text-green-100 z-1 w-20 h-20  bg-green-600 rounded-b-full p-4' />
                    <div className='relative text-3xl text-green-800 font-bold'>
                        <h3>Econ</h3>
                    </div>
                    <div className='text-green-700 flex text-lg flex-col gap-4 flex-1 justify-center font-semibold'>
                        {beneficionsEcon.map((beneficion, index) => (
                            <p key={index + "econ"}>{beneficion}</p>
                        ))}
                    </div>

                    <Link to="/planos" state={{ "planoSelecionado": "Econ" }} className='w-full p-4 bg-green-600 rounded-lg text-white font-bold shadow hover:shadow-2xl transition duration-200'>Ver mais </Link>
                </div>



                <div className={classNames(planosVisiveis ? 'animate-fromBottom  visible' : "invisible", 'flex flex-col gap-8 items-center h-full shadow-lg hover:shadow-2xl transition duration-200 cursor-default bg-green-900 p-12')}>
                    <ShieldCheckIcon className='top-0 text-green-100 z-1 w-20 h-20 bg-green-600 rounded-full p-4' />
                    <div className='relative text-3xl text-white font-bold'>
                        <h3>Total</h3>
                    </div>
                    <div className='text-gray-200 flex text-lg flex-col gap-4 flex-1 justify-center font-semibold'>
                        {beneficionsTotal.map((beneficion, index) => (
                            <p key={index + "total"}>{beneficion}</p>
                        ))}
                    </div>

                    <Link to="/planos" state={{ "planoSelecionado": "Total" }} className='w-full p-4 bg-green-600 rounded-lg text-white font-bold shadow hover:shadow-2xl transition duration-200'>Ver mais </Link>
                </div>



                <div className={classNames(planosVisiveis ? 'animate-fromRight visible' : "invisible", 'bg-gradient-to-l from-green-300 to-green-400 flex flex-col gap-8 items-center lg:min-h-[700px] shadow-lg hover:shadow-2xl transition duration-200 cursor-default bg-green-300 p-12')}>
                    <OfficeBuildingIcon className='top-0 text-green-100 z-1 w-20 h-20 bg-green-600 rounded-lg p-4' />
                    <div className='relative text-3xl text-green-800 font-bold'>
                        <h3>Empresarial</h3>
                    </div>
                    <div className='text-green-700 flex text-lg flex-col gap-4 flex-1 justify-center font-semibold'>
                        {beneficionsEmpresarial.map((beneficion, index) => (
                            <p key={index + "empresa"}>{beneficion}</p>
                        ))}
                    </div>

                    <Link to="/planos" state={{ "planoSelecionado": "Empresarial" }} className='w-full p-4 bg-green-600 rounded-lg text-white font-bold shadow hover:shadow-2xl transition duration-200'>Ver mais </Link>
                </div>
            </div>

            <Link to="/planos" className='block text-lg text-green-900 lg:pt-44 pt-32'>
                Confira mais detalhes sobre os <strong className='text-green-600 font-bold text-2xl mx-1'>Planos de Assistência Familiar</strong>
            </Link>
        </div>
    )
}
