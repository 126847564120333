import React, { useState, useEffect, useRef } from 'react'
import TimeLine from '../../components/TimeLine'
import { CheckCircleIcon, CheckIcon, XIcon } from '@heroicons/react/solid'
import { useLocation } from 'react-router-dom';
import useIsInViewport from '../../components/useIsInViewport';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Planos() {
    const [planoSelecionado, setPlanoSelecionado] = useState(0)
    const planosRef = useRef(null);
    const tituloPlanosRef = useRef(null);
    const planoIsInViewport = useIsInViewport(planosRef);
    const tituloIsInViewport = useIsInViewport(tituloPlanosRef);

    const imageRef = useRef(null);
    const imageIsInViewport = useIsInViewport(imageRef);

    let location = useLocation();

    const PrimeiroTexto = () => {
        return (
            <div className='flex-1 flex flex-col gap-4'>
                <p>
                    Antes de mais nada, quando o assunto é a família, sabe-se que é muito importante cuidar da saúde daqueles que se ama e ter condições de dar uma despedida digna a um ente querido, sem comprometer o orçamento da família.
                </p>
                <p>
                    Nesse quesito, pode-se notar os inúmeros benefícios e vantagens de um plano de assistência familiar, que oferece todos os meios necessários para que todo o círculo familiar tenha suporte e tranqüilidade, principalmente, no momento da despedida.
                    Em suma, um plano de assistência familiar é um plano de assistência funeral familiar acrescido de benefícios para a família.
                </p>
                <p>
                    Diferente de outros modelos disponíveis em empresas concorrentes, o Plano de Assistência Familiar oferecido pelo Grupo PLASFRAN aos seus clientes é completo, engloba:</p>
            </div>
        )
    }

    const SegundoTexto = () => {
        return (
            <ul className='flex-1 flex flex-col gap-4 text-green-600'>
                <li className='flex gap-2 items-center'> <CheckCircleIcon className='w-4 h-4 shrink-0' /> Qualidade e garantia em todos os produtos e serviços especializados;</li>
                <li className='flex gap-2 items-center'> <CheckCircleIcon className='w-4 h-4 shrink-0' /> Agilidade e disponibilidade no atendimento;</li>
                <li className='flex gap-2 items-center'> <CheckCircleIcon className='w-4 h-4 shrink-0' /> Serviços de saúde e serviços funerários devidamente regliamentados;</li>
                <li className='flex gap-2 items-center'> <CheckCircleIcon className='w-4 h-4 shrink-0' /> Estruturas e serviços próprios com o melhor custo-benefício do mercado;</li>
                <li className='flex gap-2 items-center'> <CheckCircleIcon className='w-4 h-4 shrink-0' /> Equipe composta por grandes profissionais qualificados;</li>
                <li className='flex gap-2 items-center'> <CheckCircleIcon className='w-4 h-4 shrink-0' /> Maior rede de empresas parceiras e profissionais conveniados com condições exclusivas;</li>
                <li className='flex gap-2 items-center'> <CheckCircleIcon className='w-4 h-4 shrink-0' /> Acesso a consultas particulares com descontos de até 50% para clientes associados.</li>
            </ul>
        )
    }

    const salarioMinimo = 1212

    const items = [
        "Titular contratante",
        "Cônjuge ou companheiro(a)",
        "Filhos, sem limite de número, idade e estado civil",
        "Dependentes extras de escolha do titular, sem custo adicional",
        "Inclusão ilimitada de dependentes especiais de escolha do titular *",
        "Cobertura parcial na clínica médica e odontológica",
        "+ acesso aos demais benefícios",
        "Cobertura na assistência funerária",
        "Providências administrativas legais para realização do funeral",
        "Assistência pós-óbito, indicadores e orientações *",
        "Assistência jurídica previdenciária e fúnebre",
        "1 urna com visor e alça barreira",
        "1 urna mortuária com visor e varão, alto padrão sextavada, lisa decorada, envernizada, no tamanho adequado a necessidade",
        "Ornamentação da urna com flores do campo naturais",
        "1 coroa de flores do campo naturais",
        "Buquês de flores naturais",
        "4 velas fúnebres 24 horas",
        "1 veste simples",
        "1 veste de luxo e edredom de cetim",
        "1 véu",
        "1 mesa de condolências",
        "1 livro para registro de presença",
        "Montagem de parâmetros de acordo com o credo religioso",
        "Centro Ecumênico",
        "Salão de velório",
        "Serviço de copa *",
        "Kit de mantimentos para velório residencial *",
        "Assistência de técnica de enfermagem *",
        "Serviço do Agente Funerário *",
        "Serviço de tanatopraxia e embalsamento *",
        "1 Jazigo coletivo ou 50% de desconto na compra de um jazigo com 3 gavetas *",
        "1 Jazigo particular com 3 gavetas *",
        "Veículo para cortejo fúnebre",
        "Transporte da família para acompanhamento do cortejo fúnebre dentro do município da contratada",
        "Translado de cujus",
        "Anúncio na cidade local em rádio e/ou jornal",
        "Cartão-convite personalizado para redes sociais",
        "Central de atendimento e óbitos 24 horas, todos os dias do ano, inclusive feriados",
        "Taxa de abertura de cova ou sepultura *",
        "Cartões de agradecimento personalizados (foto-lembrança)",
        "Camisas Dry personalizadas com foto",
        "Transporte não-emergencial (ambulância) dentro do município da contratada *",
    ]

    const planos = [
        {
            plano: "Econ",
            porcentagem: 4,
            titulo: `R$ ${(4 / 100 * salarioMinimo).toFixed()},00`,
            subtitulo: 'Com base no salário mínimo vigente',
            items: [
                "true",
                "true",
                "true",
                "4",
                "true",
                "true",
                "true",
                "parcial",
                "false",
                "false",
                "false",
                "true",
                "false",
                "true",
                "TAM. médio",
                "2",
                "true",
                "true",
                "false",
                "TAM. pequeno",
                "true",
                "true",
                "false",
                "true",
                "true",
                "true",
                "simples",
                "true",
                "true",
                "mediante taxa de 50% do valor do serviço",
                "true",
                "false",
                "true",
                "true",
                "até 250 km, dentro de todo o estado do Ceará",
                "true",
                "true",
                "true",
                "true",
                "40",
                "5",
                "false",
            ],
        },
        {
            plano: "Total",
            porcentagem: 6,
            titulo: `R$ ${(6 / 100 * salarioMinimo).toFixed()},00`,
            subtitulo: 'Com base no salário mínimo vigente',
            items: [
                "true",
                "true",
                "true",
                "2",
                "true",
                "true",
                "true",
                "total",
                "true",
                "true",
                "true",
                "false",
                "true",
                "+ rosas naturais",
                "TAM. grande",
                "4",
                "true",
                "false",
                "true",
                "trabalhado + TAM.  grande",
                "true",
                "true",
                "true",
                "true",
                "true",
                "true",
                "completo",
                "true",
                "true",
                "sem custos adicionais",
                "false",
                "true",
                "true",
                "true",
                "dentro de todo o estado do Ceará",
                "true",
                "true",
                "true",
                "true",
                "50",
                "10",
                "true",
            ],
        },
        {
            plano: "Empresarial",
            porcentagem: 1,
            titulo: `R$ ${(1 / 100 * salarioMinimo).toFixed()},00`,
            subtitulo: 'Com base no salário mínimo vigente',
            items: [
                "Colaborador contratante de empresa associada",
                "false",
                "false",
                "false",
                "false",
                "true",
                "true",
                "total",
                "true",
                "true",
                "true",
                "false",
                "true",
                "+ rosas naturais",
                "TAM. grande",
                "4",
                "true",
                "false",
                "true",
                "trabalhado + TAM.  grande",
                "true",
                "true",
                "true",
                "true",
                "true",
                "true",
                "completo",
                "true",
                "true",
                "sem custos adicionais",
                "false",
                "true",
                "true",
                "true",
                "dentro de todo o estado do Ceará",
                "true",
                "true",
                "true",
                "true",
                "50",
                "10",
                "true",
            ],
        }
    ]

    useEffect(() => {
        window.scrollTo(0, 0);
        const nomePlanoInicial = location.state ? location.state.planoSelecionado : "Econ";
        const planoInicial = planos.findIndex(plano => plano.plano === nomePlanoInicial);

        if (location.state && location.state.planoSelecionado) {
            tituloPlanosRef.current?.scrollIntoView({ behavior: 'smooth' })
        }

        setPlanoSelecionado(planoInicial);
    }, [location])

    return (
        <main className="text-center scroll-smooth w-full h-full bg-fixed bg-contain bg-center bg-green-50">
            <section className='max-w-5xl mx-auto  flex flex-col gap-8 h-fit w-full py-16 px-8'>
                <h1 className='text-4xl text-green-900 font-bold'>
                    Plano de Assistência Familiar (PAF)
                </h1>

                <div className="text-xl flex flex-col gap-4 text-left">
                    <div className='lg:flex flex-row items-center gap-4'>
                        <PrimeiroTexto />
                    </div>
                    <div className='lg:flex flex-row items-center gap-4'>
                        <SegundoTexto />
                        <div ref={imageRef} className={classNames(imageIsInViewport ? 'animate-fromRight visible' : 'invisible', 'h-80 lg:w-80 w-full rounded-full')}>
                            <img
                                src={require('../../assets/desenhos/planos.png')}
                                alt="logo-plasfran"
                                className='h-full w-full object-contain' />
                        </div>
                    </div>
                </div>
            </section>

            <section className='max-w-5xl mx-auto flex flex-col text-green-900 items-center h-fit w-full py-16 px-8'>
                <h1 ref={tituloPlanosRef} className='text-4xl font-bold mb-8'>
                    Comparativo dos planos PAF
                </h1>
                <p className='text-lg mb-8'>
                    Faça um comparativo do nosso PAF e escolha o melhor para sua família
                </p>

                <div
                    className={classNames(
                        (planoIsInViewport && !tituloIsInViewport) ? 'fixed top-0 px-8 animate-fromTop' : 'relative',
                        'grid grid-cols-1 md:grid-cols-6 z-20 max-w-5xl mx-auto w-full bg-green-50'
                    )}>
                    <div className='col-span-1 p-2 m-0.5'></div>
                    <div className='col-span-5 grid grid-cols-3'>
                        {planos.map((item, index) => {
                            return (
                                <div key={index + "titulo"} className='text-green-800 p-2 m-0.5 bg-green-400 flex items-center justify-center text-lg font-bold break-all'>{item.plano}</div>
                            )
                        })}
                    </div>
                </div>

                <div ref={planosRef} className='grid grid-cols-1 md:grid-cols-6 w-full'>
                    {items.map((item, index) => {
                        return (
                            <>
                                <div key={index + "item"} className='col-span-1 bg-green-200 p-2 m-0.5 flex justify-center items-center'>{item}</div>
                                <div key={index + "plano"} className='col-span-5 grid grid-cols-3'>
                                    <div className='p-2 m-0.5 bg-green-100 flex items-center justify-center'>
                                        {planos[0].items[index] == "true" ? <CheckIcon className="w-5 h-5" /> :
                                            planos[0].items[index] == "false" ? <XIcon className='w-5 h-5' /> :
                                                planos[0].items[index]}
                                    </div>
                                    <div className='p-2 m-0.5 bg-green-100 flex items-center justify-center'>
                                        {planos[1].items[index] == "true" ? <CheckIcon className="w-5 h-5" /> :
                                            planos[1].items[index] == "false" ? <XIcon className='w-5 h-5' /> :
                                                planos[1].items[index]}
                                    </div>
                                    <div className='p-2 m-0.5 bg-green-100 flex items-center justify-center'>
                                        {planos[2].items[index] == "true" ? <CheckIcon className="w-5 h-5" /> :
                                            planos[2].items[index] == "false" ? <XIcon className='w-5 h-5' /> :
                                                planos[2].items[index]}
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>

            </section>
        </main>
    )
}
