import React, { useRef } from 'react'
import { BadgeCheckIcon, OfficeBuildingIcon, ShieldCheckIcon } from '@heroicons/react/solid'
import { CalendarIcon, LocationMarkerIcon, PhoneIcon } from '@heroicons/react/outline'
import useIsInViewport from '../../../components/useIsInViewport'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function UnidadesEmpresa() {
    const unidadesEmpresa = [
        {
            nome: "Funerária",
            endereco: ['Rua Jânio Quadros, nº 187, Centro, Sobral-CE'],
            numeros: ["(88) 3611.2400", "(88) 9 9965-7559"],
            funcionamento: [
                `Horários de atendimento presencial e teleatendimento
                    07h00 as 18h00, de segunda-feira a sexta-feira`,
                `Horário da assistência fúnebre
                    24h (quando houver), de segunda-feira a domingo`
            ],
            imagem: require('../../../assets/unidades/hemoce.jpg'),
            ref: useRef(null)
        },
        {
            nome: "Cemitério",
            endereco: [
                `CEMITÉRIO PLASFRAN JARDIM ETERNO
                BR-222, 1767, Dom José, Sobral-CE
                (88) 9 9798-0064`,

                `CEMITÉRIO PLASFRAN JARDIM DA PAZ
                Ac. Jordão, S/N, Distrito Industrial, Sobral-CE
                (88) 9 9798-0064`
            ],
            numeros: ["(88) 9 99497-3893"],
            funcionamento: [
                `Horários de atendimento presencial e teleatendimento
                    07h00 as 18h00, de segunda-feira a sexta-feira`,

                `Horários de visitação
                    07h00 as 22h00, de segunda-feira a domingo`,

                `Horários de velórios
                    24h (quando houver), de segunda-feira a domingo
                `,
            ],
            imagem: require('../../../assets/unidades/cemiterio.jpg'),
            ref: useRef(null)
        },
        {
            nome: "Clínica Médica",
            endereco: ['Rua Menino Deus, 505, Centro, Sobral-CE'],
            numeros: ["(88) 99798-0053"],
            funcionamento: [
                "07h30 as 18h00, de segunda-feira a sexta-feira",
                "07h30 as 12h00, aos sábado"
            ],
            imagem: require('../../../assets/unidades/centro_clinico.jpg'),
            ref: useRef(null)
        },
        {
            nome: "Clínica Odontológica",
            endereco: ['Rua Menino Deus, 505, Centro, Sobral-CE'],
            numeros: ["(88) 99221-2889"],
            funcionamento: [
                "08h00 as 12h00 e 14h00 as 18h00, de segunda-feira a sexta-feira",
                "08h00 as 12h00, aos sábado"
            ],
            imagem: require('../../../assets/unidades/centro_clinico.jpg'),
            ref: useRef(null)
        },
        {
            nome: "Ótica",
            endereco: ["Rua Menino Deus, 505, Centro, Sobral-CE"],
            numeros: ["(88) 99721-2500"],
            funcionamento: [
                "07h30 as 17h30, de segunda-feira a sexta-feira",
                "07h30 as 11h30, aos sábado"
            ],
            imagem: require('../../../assets/unidades/otica.jpg'),
            ref: useRef(null)
        },
        {
            nome: "Farmácia",
            endereco: ["Av. Min. César Cals, 741, Cidade Dr. José Euclides Ferreira Gomes Junior, Sobral-CE"],
            numeros: ["(88) 99497-3983"],
            funcionamento: [
                "08h00 as 20h00, de segunda-feira a sábado"
            ],
            imagem: require('../../../assets/unidades/farmacia.jpg'),
            ref: useRef(null)
        },
    ]

    const servicosVisiveis = [
        { visivel: useIsInViewport(unidadesEmpresa[0].ref) },
        { visivel: useIsInViewport(unidadesEmpresa[1].ref) },
        { visivel: useIsInViewport(unidadesEmpresa[2].ref) },
        { visivel: useIsInViewport(unidadesEmpresa[3].ref) },
        { visivel: useIsInViewport(unidadesEmpresa[4].ref) },
        { visivel: useIsInViewport(unidadesEmpresa[5].ref) },
    ]

    return (
        <div className='max-w-5xl mx-auto'>
            <div className='flex flex-col items-center py-4 gap-4'>
                <h1 className='text-4xl text-green-900 font-bold'>Unidade <strong className='font-semibold text-green-600'>PLASFRAN</strong> em Sobral</h1>
                <p className='text-lg text-green-900'>
                    Confira aqui o endereço e número de contato da nossa sede principal e demais unidades de serviço
                </p>
            </div>

            <div className='grid grid-cols-1 lg:grid-cols-4 gap-16 pt-32 w-full text-left text-sm'>
                {unidadesEmpresa.map((unidade, index) => (
                    <div
                        key={index}
                        ref={unidade.ref}
                        className={
                            classNames(
                                servicosVisiveis[index].visivel ? "animate-fromBottom visible" : "invisible",
                                index < 2 ? "lg:col-span-4" : "lg:col-span-2",
                                'text-green-600 bg-white hover:bg-green-50 border border-green-600 grid grid-cols-1 sm:grid-cols-3 h-full rounded-lg p-2 shadow-lg hover:shadow-2xl transition duration-200 cursor-default'
                            )
                        }
                    >
                        <div className='bg-green-20'>
                            <img
                                className="h-full w-full col-span-1 object-cover aspect-square rounded opacity-1"
                                src={unidade.imagem}
                                alt=""
                            />
                        </div>
                        <div className='p-4 col-span-2'>
                            <h2 className=' text-2xl text-green-900 font-bold'>{unidade.nome}</h2>
                            <div className='flex text-green-600'>
                                <PhoneIcon className='inline-block mr-2 w-5 h-5 shrink-0 mt-2' />
                                <div>
                                    {unidade.numeros.map((numero, index) => (
                                        <div key={index} className='font-semibold mt-2'>
                                            {numero}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {unidade.endereco &&
                                <div className='flex-1 flex text-green-600'>
                                    <LocationMarkerIcon className='inline-block mr-2 w-5 h-5 shrink-0 mt-3' />
                                    <div>
                                        {unidade.endereco.map((endereco, index) => (
                                            <div key={index} className='font-semibold mt-2'>
                                                <p>{endereco}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }

                            {unidade.funcionamento &&
                                <div className='flex-1 flex text-green-600'>
                                    <CalendarIcon className='inline-block mr-2 w-5 h-5 shrink-0 mt-3' />
                                    <div>
                                        {unidade.funcionamento.map((numero, index) => (
                                            <div key={index} className='font-semibold mt-2'>
                                                <p>{numero}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                ))}
            </div>
        </div>
    )
}
